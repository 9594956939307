import api from './api'
export default {
    wildlogin(data){
        return api.execute(`post`,`/manager/wildlogin`,data)
    },
    getMonitors(){
        return api.execute(`get`,`/manager/monitor`)
    },
    filterMonitors(data){
        return api.execute(`get`,`/manager/monitor?`+data)
    },
    getIncedent(data){
        return api.execute(`get`,`/manager/incedents`+data)
    },
    getEvents(data){
        return api.execute(`get`,`/manager/eventslogs`+data)
    },
    createPlans(data){
        return api.execute(`post`,`/manager/plan`,data)
    },
    getPlans(){
        return api.execute(`get`,`/manager/plan`)
    },
    getProduct(){
        return api.execute(`get`,`/manager/products`)
    },
    deletePlan(data){
        return api.execute(`delete`,`/manager/plan`+data)
    },
    editPlan(data){
        return api.execute(`put`,`/manager/plan`,data)
    },
    allocatePlan(data){
        return api.execute(`put`,`/manager/allocateplan`,data)
    },
    getActivityLogs(data){
        return api.execute(`post`,`/manager/activity`,data)
    },
    createAffiliate(data){
        return api.execute(`post`,`/manager/aff`,data)
    },
    getAffiliate(){
        return api.execute(`get`,`/manager/aff`)
    },
    editAffiliate(data){
        return api.execute(`put`,`/manager/aff`,data)
    },
    deleteAffiliate(data){
        return api.execute(`delete`,`/manager/aff`,data)
    },
    getAffiliateLogs(data){
        return api.execute(`post`,`/manager/aff/logs`,data)
    },
    getrefLogs(data){
        return api.execute(`post`,`/manager/aff/reflogs`,data)
    },
    addBlacklist(data){
        return api.execute(`post`,`/manager/aff/blockref`,data)
    },
    getblkaffuser(){
        return api.execute(`get`,`/manager/aff/blockref`)
    },
    deleteblklstuser(data){
        return api.execute(`delete`,`/manager/aff/blockref`,data)
    },
    getReferralPayment(data){
        return api.execute(`post`,`/manager/aff/referal/get`,data)
    },
    PayOut(data){
        return api.execute(`post`,`/manager/aff/referal/pay`,data)
    },
    getUserInteractEvents(){
        return api.execute(`get`,`/manager/user/events`)
    },
    updateUserInteractEvent(data){
        return api.execute(`put`,`/manager/user/events`,data)
    },
    getSourceList(){
        return api.execute(`get`,`/manager/aff/source`)
    },
}